import React from 'react';
import MuffinHotTub from './components/MuffinHotTub';
import { Analytics } from "@vercel/analytics/react";
import './App.css';

function App() {
  return (
    <div>
      <MuffinHotTub />
      <Analytics />
    </div>
  );
}

export default App;