import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { AlertTriangle, BadgeHelp, Zap, Heart, Shield, Clock, Star, Bomb, Maximize2 } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from "./ui/alert"
import { Progress } from './ui/progress';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { cn } from "../lib/utils";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { motion, AnimatePresence } from 'framer-motion';

interface Pigeon {
  id: number;
  x: number;
  y: number;
  dx: number;
  dy: number;
  type: keyof typeof PIGEON_TYPES;
}

interface Powerup {
  id: number;
  x: number;
  y: number;
  dy: number;
  type: keyof typeof POWERUP_TYPES;
}

interface Particle {
  id: number;
  x: number;
  y: number;
  color: string;
}

const PIGEON_TYPES = {
  normal: { color: 'gray', speed: 1, points: 1, size: 1 },
  golden: { color: 'gold', speed: 1.5, points: 5, size: 1 },
  speedy: { color: 'red', speed: 2, points: 3, size: 1 },
  tiny: { color: 'lightblue', speed: 1.2, points: 2, size: 0.7 },
  bomb: { color: 'black', speed: 1.5, points: -1, size: 1 },
};

const POWERUP_TYPES = {
  slow: { icon: <Clock size={15} />, color: 'lightblue', duration: 5000 },
  magnet: { icon: <Zap size={15} />, color: 'yellow', duration: 5000 },
  shield: { icon: <Shield size={15} />, color: 'green', duration: 5000 },
  doublePoints: { icon: <Star size={15} />, color: 'purple', duration: 5000 },
  multiCatch: { icon: <Maximize2 size={15} />, color: 'orange', duration: 5000 },
};

const POWERUP_SIZE = 20;

const PigeonComponent: React.FC<{ x: number; y: number; type: keyof typeof PIGEON_TYPES; PIGEON_SIZE: number }> = ({ x, y, type, PIGEON_SIZE }) => (
  <div style={{
    position: 'absolute',
    left: x,
    top: y,
    width: PIGEON_SIZE * PIGEON_TYPES[type].size,
    height: PIGEON_SIZE * PIGEON_TYPES[type].size,
    backgroundColor: PIGEON_TYPES[type].color,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'transform 0.1s',
    transform: `rotate(${Math.random() * 360}deg)`,
  }}>
    {type === 'bomb' ? 
      <Bomb size={PIGEON_SIZE * PIGEON_TYPES[type].size * 0.6} color="white" /> : 
      <img src="/images/muffin.png" alt="Muffin" style={{ width: '80%', height: '80%' }} />
    }
  </div>
);

const Catcher: React.FC<{ x: number; y: number; isPoweredUp: keyof typeof POWERUP_TYPES | false; CATCHER_SIZE: number }> = ({ x, y, isPoweredUp, CATCHER_SIZE }) => (
  <motion.div
    style={{
      position: 'absolute',
      left: x,
      top: y,
      width: CATCHER_SIZE,
      height: CATCHER_SIZE,
      backgroundColor: isPoweredUp ? POWERUP_TYPES[isPoweredUp].color : '#8B4513',
      borderRadius: '0 0 50% 50%',
      cursor: 'none',
      boxShadow: isPoweredUp ? `0 0 10px ${POWERUP_TYPES[isPoweredUp].color}` : 'inset 0 -5px 10px rgba(0,0,0,0.3)',
      borderTop: '5px solid #A0522D',
      backgroundImage: 'repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(0,0,0,0.1) 5px, rgba(0,0,0,0.1) 10px)',
    }}
    animate={{
      scale: [1, 1.05, 1],
      transition: { duration: 0.5, repeat: Infinity },
    }}
  />
);

const PowerupComponent: React.FC<{ x: number; y: number; type: keyof typeof POWERUP_TYPES }> = ({ x, y, type }) => (
  <motion.div
    style={{
      position: 'absolute',
      left: x,
      top: y,
      width: POWERUP_SIZE,
      height: POWERUP_SIZE,
      backgroundColor: POWERUP_TYPES[type].color,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: `0 0 10px ${POWERUP_TYPES[type].color}`,
    }}
    animate={{
      scale: [1, 1.1, 1],
      boxShadow: [
        `0 0 10px ${POWERUP_TYPES[type].color}`,
        `0 0 20px ${POWERUP_TYPES[type].color}`,
        `0 0 10px ${POWERUP_TYPES[type].color}`,
      ],
    }}
    transition={{ duration: 1, repeat: Infinity }}
  >
    {POWERUP_TYPES[type].icon}
  </motion.div>
);

const ParticleComponent: React.FC<{ x: number; y: number; color: string }> = ({ x, y, color }) => (
  <motion.div
    initial={{ x, y, opacity: 1, scale: 1 }}
    animate={{
      x: x + (Math.random() - 0.5) * 100,
      y: y + (Math.random() - 0.5) * 100,
      opacity: 0,
      scale: 0,
    }}
    transition={{ duration: 0.5 }}
    style={{
      position: 'absolute',
      width: 5,
      height: 5,
      borderRadius: '50%',
      backgroundColor: color,
      
    }}
  />
);

const playSoundEffect = (type: 'catch' | 'powerup' | 'bomb' | 'levelup' | 'streak') => {
  const audio = new Audio(`/sounds/${type}.mp3`);
  audio.play();
};

const MovingCloud: React.FC<{ speed: number }> = ({ speed }) => {
  const [position, setPosition] = useState(Math.random() * 100);

  useEffect(() => {
    const animate = () => {
      setPosition((prev) => (prev + speed) % 120 - 20);
      requestAnimationFrame(animate);
    };
    const animationId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationId);
  }, [speed]);

  return (
    <div
      className="absolute opacity-30"
      style={{
        left: `${position}%`,
        top: `${Math.random() * 80}%`,
        transform: `scale(${0.5 + Math.random() * 0.5})`,
      }}
    >
      ☁️
    </div>
  );
};

export default function MuffinHotTub() {
  const isMobile = useMediaQuery('(max-width: 640px)');

  // Adjust sizes based on screen size
  const PIGEON_SIZE = isMobile ? 20 : 30;
  const CATCHER_SIZE = isMobile ? 40 : 50;

  const [pigeons, setPigeons] = useState<Pigeon[]>([]);
  const [powerups, setPowerups] = useState<Powerup[]>([]);
  const [catcher, setCatcher] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [level, setLevel] = useState(1);
  const [isPoweredUp, setIsPoweredUp] = useState<keyof typeof POWERUP_TYPES | false>(false);
  const [lives, setLives] = useState(3);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const gameAreaRef = useRef<HTMLDivElement>(null);
  const [gameDimensions, setGameDimensions] = useState({ width: 600, height: 400 });
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [combo, setCombo] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [difficulty, setDifficulty] = useState<'easy' | 'normal' | 'hard'>('normal');
  const [pigeonSpawnRate, setPigeonSpawnRate] = useState(1500);
  const [powerupSpawnRate, setPowerupSpawnRate] = useState(10000);
  const [bombHit, setBombHit] = useState(false);
  const [particles, setParticles] = useState<Particle[]>([]);
  const [powerupTimeLeft, setPowerupTimeLeft] = useState(0);
  const [showLevelUp, setShowLevelUp] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [streak, setStreak] = useState(0);
  const [streakBonus, setStreakBonus] = useState(0);
  const [powerupMeter, setPowerupMeter] = useState(0);
  const [difficultyIncrease, setDifficultyIncrease] = useState(0);
  const [catcherTrail, setCatcherTrail] = useState<{ x: number; y: number }[]>([]);
  const [shake, setShake] = useState(false);

  // New state for muffins and hot tub
  const [muffins, setMuffins] = useState<{ x: number; y: number }[]>([]);
  const [hotTubPosition, setHotTubPosition] = useState(0);

  const [gameOverSound, setGameOverSound] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    const audio = new Audio('/sounds/game_over.mp3');
    audio.addEventListener('error', (e) => {
      console.error('Error loading audio:', e);
    });
    audio.addEventListener('canplaythrough', () => {
      setGameOverSound(audio);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setMuffins((prevMuffins) => [
        ...prevMuffins,
        { x: Math.random() * (gameDimensions.width - 50), y: 0 },
      ]);
    }, 2000);

    return () => clearInterval(interval);
  }, [gameDimensions.width]);

  useEffect(() => {
    console.log(muffins); // Add this line to check the muffins state
  }, [muffins]);

  useEffect(() => {
    const moveHotTub = (e: MouseEvent) => {
      setHotTubPosition(e.clientX - 50);
    };

    window.addEventListener('mousemove', moveHotTub);

    return () => window.removeEventListener('mousemove', moveHotTub);
  }, []);

  useEffect(() => {
    const gameLoop = setInterval(() => {
      setMuffins((prevMuffins) => {
        return prevMuffins.map((muffin) => {
          const newY = muffin.y + 5;
          if (newY > gameDimensions.height - 100 &&
              Math.abs(muffin.x - hotTubPosition) < 50) {
            setScore((prevScore) => prevScore + 1);
            return null;
          }
          return newY > gameDimensions.height ? null : { ...muffin, y: newY };
        }).filter((muffin): muffin is { x: number; y: number } => muffin !== null);
      });
    }, 50);

    return () => clearInterval(gameLoop);
  }, [hotTubPosition, gameDimensions.height]);

  const addPigeon = useCallback(() => {
    const types = Object.keys(PIGEON_TYPES) as Array<keyof typeof PIGEON_TYPES>;
    const type = types[Math.floor(Math.random() * types.length)];
    const pigeonType = PIGEON_TYPES[type];
    return {
      id: Date.now(),
      x: Math.random() * (gameDimensions.width - PIGEON_SIZE * pigeonType.size),
      y: 0,
      dx: (Math.random() - 0.5) * (5 + level) * pigeonType.speed,
      dy: Math.random() * (3 + level) * pigeonType.speed,
      type: type
    };
  }, [gameDimensions.width, level, PIGEON_SIZE]);

  const addPowerup = useCallback(() => {
    const types = Object.keys(POWERUP_TYPES);
    const type = types[Math.floor(Math.random() * types.length)] as keyof typeof POWERUP_TYPES;
    return {
      id: Date.now(),
      x: Math.random() * (gameDimensions.width - POWERUP_SIZE),
      y: 0,
      dy: 2,
      type: type
    };
  }, [gameDimensions.width]);

  const movePigeons = useCallback(() => {
    setPigeons(prev => prev.map(pigeon => {
      const newX = pigeon.x + pigeon.dx;
      const newY = pigeon.y + pigeon.dy;
      return {
        ...pigeon,
        x: newX,
        y: newY,
        dx: (newX > gameDimensions.width - PIGEON_SIZE * PIGEON_TYPES[pigeon.type].size || newX < 0)
          ? -pigeon.dx : pigeon.dx,
        dy: (newY > gameDimensions.height - PIGEON_SIZE * PIGEON_TYPES[pigeon.type].size || newY < 0)
          ? -pigeon.dy : pigeon.dy
      };
    }));
  }, [gameDimensions, PIGEON_SIZE]);

  const movePowerups = useCallback(() => {
    setPowerups(prev => prev.map(powerup => ({
      ...powerup,
      y: powerup.y + powerup.dy,
    })).filter(powerup => powerup.y < gameDimensions.height));
  }, [gameDimensions.height]);

  const activatePowerup = useCallback((type: keyof typeof POWERUP_TYPES) => {
    setIsPoweredUp(type);
    setPowerupTimeLeft(POWERUP_TYPES[type].duration / 1000);
    if (type === 'slow') {
      setPigeons(prev => prev.map(p => ({ ...p, dx: p.dx / 2, dy: p.dy / 2 })));
    } else if (type === 'magnet') {
      // Implement magnet logic
    }
    setTimeout(() => {
      setIsPoweredUp(false);
      if (type === 'slow') {
        setPigeons(prev => prev.map(p => ({ ...p, dx: p.dx * 2, dy: p.dy * 2 })));
      }
    }, POWERUP_TYPES[type].duration);
  }, []);

  const checkCollisions = useCallback(() => {
    setPigeons(prev => {
      let newScore = score;
      let newCombo = combo;
      let bombCaught = false;
      const updatedPigeons: Pigeon[] = [];
      const newParticles: Particle[] = [];

      for (const pigeon of prev) {
        const caught = pigeon.x < catcher.x + CATCHER_SIZE &&
                       pigeon.x + PIGEON_SIZE * PIGEON_TYPES[pigeon.type].size > catcher.x &&
                       pigeon.y < catcher.y + CATCHER_SIZE &&
                       pigeon.y + PIGEON_SIZE * PIGEON_TYPES[pigeon.type].size > catcher.y;
        
        if (caught) {
          if (pigeon.type === 'bomb') {
            bombCaught = true;
            setBombHit(true);
            setShake(true);
            setTimeout(() => {
              setBombHit(false);
              setShake(false);
            }, 1000);
            playSoundEffect('bomb');
          } else {
            newCombo++;
            const streakBonus = Math.floor(newCombo / 5); // Bonus every 5 catches
            const points = PIGEON_TYPES[pigeon.type].points * (isPoweredUp === 'doublePoints' ? 2 : 1) * (1 + newCombo * 0.1 + streakBonus);
            newScore += Math.round(points);
            for (let i = 0; i < 20; i++) {
              newParticles.push({
                id: Math.random(),
                x: pigeon.x + PIGEON_SIZE / 2,
                y: pigeon.y + PIGEON_SIZE / 2,
                color: PIGEON_TYPES[pigeon.type].color
              });
            }
            playSoundEffect('catch');
          }
        } else {
          updatedPigeons.push(pigeon);
        }
      }

      if (newScore !== score) {
        setScore(newScore);
        setCombo(newCombo);
      } else {
        setCombo(0);
      }
      if (bombCaught && isPoweredUp !== 'shield') {
        setLives(l => {
          if (l <= 1) {
            setGameOver(true);
            return 0;
          }
          return l - 1;
        });
      }
      if (newParticles.length > 0) {
        setParticles(prev => [...prev, ...newParticles]);
      }
      return updatedPigeons;
    });

    setPowerups(prev => prev.filter(powerup => {
      const caught = powerup.x < catcher.x + CATCHER_SIZE &&
                     powerup.x + POWERUP_SIZE > catcher.x &&
                     powerup.y < catcher.y + CATCHER_SIZE &&
                     powerup.y + POWERUP_SIZE > catcher.y;
      if (caught) {
        activatePowerup(powerup.type);
        playSoundEffect('powerup');
      }
      return !caught;
    }));

    if (isPoweredUp !== 'shield') {
      setPigeons(prev => {
        if (prev.some(pigeon => pigeon.y > gameDimensions.height)) {
          setLives(l => {
            if (l <= 1) {
              setGameOver(true);
            }
            return l - 1;
          });
          return prev.filter(pigeon => pigeon.y <= gameDimensions.height);
        }
        return prev;
      });
    }
  }, [catcher, isPoweredUp, gameDimensions.height, score, combo, activatePowerup, CATCHER_SIZE, PIGEON_SIZE]);

  useEffect(() => {
    if (score > highScore) {
      setHighScore(score);
      localStorage.setItem('highScore', score.toString());
    }
  }, [score, highScore]);

  useEffect(() => {
    const storedHighScore = localStorage.getItem('highScore');
    if (storedHighScore) {
      setHighScore(parseInt(storedHighScore, 10));
    }
  }, []);

  useEffect(() => {
    if (gameOver || isPaused) return;

    const gameLoop = setInterval(() => {
      movePigeons();
      movePowerups();
      checkCollisions();
    }, 50);

    const pigeonSpawner = setInterval(() => {
      setPigeons(prev => [...prev, addPigeon()]);
    }, pigeonSpawnRate / level);

    const powerupSpawner = setInterval(() => {
      setPowerups(prev => [...prev, addPowerup()]);
    }, powerupSpawnRate);

    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          setGameOver(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      clearInterval(gameLoop);
      clearInterval(pigeonSpawner);
      clearInterval(powerupSpawner);
      clearInterval(timer);
    };
  }, [gameOver, isPaused, level, addPigeon, addPowerup, movePigeons, movePowerups, checkCollisions, pigeonSpawnRate, powerupSpawnRate]);

  useEffect(() => {
    if (gameOver) {
      if (audioRef.current) {
        audioRef.current.pause();
        setIsMusicPlaying(false);
      }
      if (gameOverSound) {
        gameOverSound.play().catch(e => console.error('Error playing audio:', e));
      }
    }
  }, [gameOver, gameOverSound]);

  const toggleMusic = () => {
    if (isMusicPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }
    setIsMusicPlaying(!isMusicPlaying);
  };

  const updateCatcherPosition = useCallback(() => {
    setCatcher(prev => {
      const newX = Math.min(Math.max(mousePosition.x - CATCHER_SIZE / 2, 0), gameDimensions.width - CATCHER_SIZE);
      const newY = Math.min(Math.max(mousePosition.y - CATCHER_SIZE / 2, 0), gameDimensions.height - CATCHER_SIZE);
      if (newX !== prev.x || newY !== prev.y) {
        setCatcherTrail(trail => [...trail.slice(-5), { x: newX, y: newY }]);
        return { x: newX, y: newY };
      }
      return prev;
    });
  }, [mousePosition, gameDimensions, CATCHER_SIZE]);

  useEffect(() => {
    let animationFrameId: number;
    const animate = () => {
      updateCatcherPosition();
      animationFrameId = requestAnimationFrame(animate);
    };
    animationFrameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameId);
  }, [updateCatcherPosition]);

  const handleMouseMove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (gameOver || isPaused) return;
    const rect = gameAreaRef.current?.getBoundingClientRect();
    if (rect) {
      setMousePosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });
    }
  }, [gameOver, isPaused]);

  const handleTouchMove = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    if (gameOver || isPaused) return;
    e.preventDefault();
    const rect = gameAreaRef.current?.getBoundingClientRect();
    const touch = e.touches[0];
    if (rect) {
      setMousePosition({
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top
      });
    }
  }, [gameOver, isPaused]);

  useEffect(() => {
    const handleResize = () => {
      const gameArea = gameAreaRef.current;
      if (gameArea) {
        const { width, height } = gameArea.getBoundingClientRect();
        setGameDimensions({ width, height });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const setGameDifficulty = (newDifficulty: 'easy' | 'normal' | 'hard') => {
    setDifficulty(newDifficulty);
    switch (newDifficulty) {
      case 'easy':
        setTimeLeft(90);
        setLives(5);
        setPigeonSpawnRate(2000);
        setPowerupSpawnRate(8000);
        break;
      case 'normal':
        setTimeLeft(60);
        setLives(3);
        setPigeonSpawnRate(1500);
        setPowerupSpawnRate(10000);
        break;
      case 'hard':
        setTimeLeft(45);
        setLives(2);
        setPigeonSpawnRate(1000);
        setPowerupSpawnRate(12000);
        break;
    }
  };

  const resetGame = () => {
    setPigeons([]);
    setPowerups([]);
    setScore(0);
    setGameOver(false);
    setLevel(1);
    setCombo(0);
    setGameDifficulty(difficulty); // This will set time, lives, and spawn rates based on difficulty
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      setIsMusicPlaying(true);
    }
  };

  const togglePause = () => {
    setIsPaused(prev => !prev);
  };

  const instructionsContent = (
    <DialogContent className="bg-white max-w-md w-full">
      <DialogHeader>
        <DialogTitle className="text-2xl font-bold mb-4">How to Play</DialogTitle>
      </DialogHeader>
      <div className="text-left">
        <ul className="space-y-4">
          <li>• Move your mouse or finger to control the catcher.</li>
          <li>• Catch pigeons before time runs out.</li>
          <li>• You start with 3 lives and 60 seconds.</li>
          <li>
            • Pigeon types and points:
            <ul className="ml-6 mt-2 space-y-1">
              <li>- Normal (gray): 1 point</li>
              <li>- Golden (gold): 5 points</li>
              <li>- Speedy (red): 3 points</li>
              <li>- Tiny (light blue): 2 points</li>
              <li>- Bomb (black): Lose a life if caught</li>
            </ul>
          </li>
          <li>
            • Power-ups and their effects:
            <ul className="ml-6 mt-2 space-y-1">
              <li>- Slow (blue clock): Slows down all pigeons</li>
              <li>- Magnet (yellow lightning): Attracts nearby pigeons</li>
              <li>- Shield (green shield): Temporary invincibility</li>
              <li>- Double Points (purple star): Doubles points briefly</li>
              <li>- Multi-Catch (orange expand): Increases catcher size</li>
            </ul>
          </li>
          <li>• Build combos by catching pigeons consecutively for bonus points.</li>
          <li>• Don't let pigeons escape, or you'll lose lives!</li>
          <li>• The game gets harder as your score increases.</li>
          <li>• Choose your difficulty: Easy, Normal, or Hard.</li>
        </ul>
      </div>
    </DialogContent>
  );

  useEffect(() => {
    if (score > level * 100) {
      setLevel(prev => prev + 1);
      setPigeonSpawnRate(prev => prev * 0.9);
      setPigeons(prev => prev.map(p => ({
        ...p,
        dx: p.dx * 1.1,
        dy: p.dy * 1.1
      })));
      setShowLevelUp(true);
      playSoundEffect('levelup');
      setTimeout(() => setShowLevelUp(false), 2000);
    }
  }, [score, level]);

  useEffect(() => {
    if (powerupTimeLeft > 0) {
      const timer = setInterval(() => {
        setPowerupTimeLeft(prev => Math.max(0, prev - 1));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [powerupTimeLeft]);

  // Memoize PigeonComponent for better performance
  const MemoizedPigeonComponent = useMemo(() => React.memo(PigeonComponent), []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-400 via-pink-500 to-red-500 sm:p-4 font-comic-sans">
      <div className="max-w-3xl mx-auto bg-white/90 rounded-lg shadow-lg overflow-hidden">
        <h1 className="text-1xl sm:text-4xl font-bold text-center text-purple-600 sm:p-4 animate-bounce mt-4">
          <img src="/images/muffin-rock.png" alt="Muffin" className="inline-block w-8 h-8 mr-2" /> Muffins in a Hot Tub 🕹️
        </h1>
        
        <div className="p-3 sm:p-4 space-y-4">
          <Alert className="mb-4">
            <AlertTriangle className="h-4 w-4" />
            <AlertTitle>Warning: Muffins on the Loose!</AlertTitle>
            <AlertDescription>Catch them all so they don't get soggy!</AlertDescription>
          </Alert>

          <div className="flex justify-between items-center">
            <button
              onClick={toggleMusic}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full text-sm transition-transform hover:scale-105 active:scale-95"
            >
              {isMusicPlaying ? '🔇 Mute' : '🔊 Play Music'}
            </button>

            <button
              onClick={togglePause}
              className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-full text-sm transition-transform hover:scale-105 active:scale-95"
            >
              {isPaused ? '▶️ Resume' : '⏸️ Pause'}
            </button>

            <div className="text-sm font-bold bg-yellow-300 px-3 py-1 rounded-full">
              High Score: {highScore}
            </div>

            <Dialog>
              <DialogTrigger asChild>
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full text-sm transition-transform hover:scale-105 active:scale-95"
                >
                  ℹ️ How to Play
                </button>
              </DialogTrigger>
              {instructionsContent}
            </Dialog>
          </div>
          
          <div className="grid grid-cols-5 gap-2 bg-gray-100 p-3 rounded-lg">
            <div className="font-bold col-span-2">Score: {score}</div>
            <div className="font-bold">Level: {level}</div>
            <div className="font-bold">{timeLeft}s</div>
            <div className="font-bold text-right">Combo: x{combo}</div>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex">
              {[...Array(lives)].map((_, i) => (
                <div key={i} className="animate-pulse">
                  <Heart size={isMobile ? 16 : 20} fill="red" color="red" />
                </div>
              ))}
            </div>
            <Progress value={(timeLeft / 60) * 100} className="w-1/2" />
          </div>

          {isPoweredUp && (
            <Progress value={(powerupTimeLeft / (POWERUP_TYPES[isPoweredUp].duration / 1000)) * 100} className="w-1/2 bg-blue-200" />
          )}

          <motion.div
            ref={gameAreaRef}
            animate={shake ? {
              x: [-5, 5, -5, 5, 0],
              transition: { duration: 0.5 }
            } : {}}
            style={{
              width: '100%',
              height: '60vh',
              maxHeight: '400px',
              border: '4px solid #8B4513',
              borderRadius: '12px',
              position: 'relative',
              overflow: 'hidden',
              cursor: 'none',
              backgroundColor: '#87CEEB',
              touchAction: 'none',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), inset 0 0 20px rgba(255, 255, 255, 0.5)',
            }}
            onMouseMove={handleMouseMove}
            onTouchMove={handleTouchMove}
          >
            {[...Array(5)].map((_, i) => (
              <MovingCloud key={i} speed={0.02 + Math.random() * 0.03} />
            ))}
            {pigeons.map(pigeon => (
              <MemoizedPigeonComponent 
                key={pigeon.id} 
                x={pigeon.x} 
                y={pigeon.y} 
                type={pigeon.type} 
                PIGEON_SIZE={PIGEON_SIZE}
              />
            ))}
            {powerups.map(powerup => (
              <PowerupComponent 
                key={powerup.id} 
                x={powerup.x} 
                y={powerup.y} 
                type={powerup.type} 
              />
            ))}
            <Catcher 
              x={catcher.x} 
              y={catcher.y} 
              isPoweredUp={isPoweredUp}
              CATCHER_SIZE={CATCHER_SIZE}
            />
            {catcherTrail.map((pos, index) => (
              <motion.div
                key={index}
                style={{
                  position: 'absolute',
                  left: pos.x,
                  top: pos.y,
                  width: CATCHER_SIZE,
                  height: CATCHER_SIZE,
                  backgroundColor: isPoweredUp ? POWERUP_TYPES[isPoweredUp].color : '#8B4513',
                  borderRadius: '0 0 50% 50%',
                  opacity: 0.2 * (index + 1) / catcherTrail.length,
                }}
              />
            ))}
            <AnimatePresence>
              {bombHit && (
                <motion.div
                  initial={{ scale: 0, rotate: 0 }}
                  animate={{ scale: 1, rotate: 360 }}
                  exit={{ scale: 0, rotate: 0 }}
                  transition={{ duration: 0.5 }}
                  className="absolute inset-0 flex items-center justify-center pointer-events-none"
                >
                  <div className="bg-red-500 text-white text-4xl font-bold p-8 rounded-full shadow-lg transform rotate-12">
                    OUCH!
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            {particles.map(particle => (
              <ParticleComponent key={particle.id} {...particle} />
            ))}
            {showLevelUp && (
              <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                className="absolute inset-0 flex items-center justify-center pointer-events-none"
              >
                <div className="bg-yellow-500 text-white text-4xl font-bold p-8 rounded-full shadow-lg">
                  Level Up!
                </div>
              </motion.div>
            )}
            {isPaused && (
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="text-white text-4xl font-bold">Paused</div>
              </div>
            )}
            {/* Add muffins and hot tub */}
            <img
              src="/images/hot-tub.png"
              alt="Hot Tub"
              className="hot-tub"
              style={{ left: `${hotTubPosition}px` }}
            />
            {muffins.map((muffin, index) => {
              console.log(`Rendering muffin at (${muffin.x}, ${muffin.y})`); // Add this line
              return (
                <img
                  key={index}
                  src="/images/muffin.png"
                  alt="Muffin"
                  className="muffin"
                  style={{
                    left: `${muffin.x}px`,
                    top: `${muffin.y}px`,
                  }}
                />
              );
            })}
          </motion.div>

          {gameOver && (
            <div
              className="mt-4 text-center bg-white/80 p-4 rounded-lg animate-fadeIn"
            >
              <h2 className="text-2xl font-bold mb-2">Game Over!</h2>
              <p className="text-xl mb-4">Final Score: {score}</p>
              <div className="mb-4 flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-4">
                {['easy', 'normal', 'hard'].map((diff) => (
                  <button
                    key={diff}
                    onClick={() => setGameDifficulty(diff as 'easy' | 'normal' | 'hard')}
                    className={cn(
                      "w-full sm:w-auto text-white font-bold py-2 px-4 rounded-full text-sm transition-transform hover:scale-105 active:scale-95",
                      {
                        'bg-green-500 hover:bg-green-700': diff === 'easy',
                        'bg-yellow-500 hover:bg-yellow-700': diff === 'normal',
                        'bg-red-500 hover:bg-red-700': diff === 'hard',
                        'ring-2 ring-blue-500': difficulty === diff
                      }
                    )}
                  >
                    {diff.charAt(0).toUpperCase() + diff.slice(1)}
                  </button>
                ))}
              </div>
              <button
                onClick={resetGame}
                className="w-full sm:w-auto bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-6 rounded-full text-lg transition-transform hover:scale-105 active:scale-95"
              >
                Play Again
              </button>
            </div>
          )}
        </div>
        
        <audio ref={audioRef} src="/game.mp3" loop />
      </div>
    </div>
  );
}